import React from "react"

import { connect } from "react-redux"
import { graphql, StaticQuery } from "gatsby"
import { Link } from "gatsby-plugin-spinner"
import PropTypes from "prop-types"
import { Index } from "elasticlunr"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import slugify from "slugify"

class Search extends React.Component {
  constructor(props) {
    super(props)
    this.state = { query: ``, results: [] }
  }

  render() {
    return (
      <div>
        <div className="field has-addon">
          <p className="control has-icons-right">
            <input className="input recherche" value={this.state.query} onChange={this.search} placeholder="Recherchez une référence ou une famille" />
            <span className="icon is-small is-right">
              <FontAwesomeIcon icon={faSearch} color="#a2388d" />
            </span>
          </p>
        </div>
        {this.state.query !== "" ? (
          <ul className={`search ${this.props.resultsover ? "over" : ""}`}>
            {this.state.results.length === 0 ? <li>Aucun résultat</li> : null}
            {this.state.results.map((page, k) => (
              <li key={k}>
                <div className="search_title">
                  {page.fa_libelle} {page.co_libelle}
                </div>
                <div className="search_buttons">
                  <Link to={`/cadres/${slugify(`${page.cat_libelle.replace('(', '').replace(')', '').replace('...', '')}-${page.fa_libelle}-${page.co_libelle}-${page.ba_id}`)}`}>
                    <button className="button is-small">Détail</button>
                  </Link>
                  {page.baguettes_doublo !== null
                    ? page.baguettes_doublo.map((e, key2) => (
                        <Link key={key2} to={`/cadres/${slugify(`${e.cat_libelle.replace('(', '').replace(')', '').replace('...', '')}-${page.fa_libelle}-${page.co_libelle}-${page.ba_id}`)}`}>
                          <button className="button is-small">Découvrir en version doublo {e.cat_libelle.replace(new RegExp(/(Cadre doublo)/gi), "")}</button>
                        </Link>
                      ))
                    : ""}
                </div>
              </li>
            ))}
          </ul>
        ) : null}
      </div>
    )
  }

  getOrCreateIndex = () =>
    this.index
      ? this.index
      : // Create an elastic lunr index and hydrate with graphql query results
        Index.load(this.props.data.siteSearchIndex.index)

  search = evt => {
    const query = evt.target.value
    this.index = this.getOrCreateIndex()
    this.setState({
      query,
      // Query the index with search string to get an [] of IDs
      results: this.index
        .search(query, { expand: true })
        // Map over each ID and return the full document
        .map(({ ref }) => this.index.documentStore.getDoc(ref)),
    })
  }
}

function mapStateToProps(state) {
  return {
    cart: state.cart,
    identifiants: state.identifiants,
  }
}
Search.defaultprops = {
  resultsover: false,
}
Search.propTypes = {
  data: PropTypes.object,
  resultsover: PropTypes.bool,
  dispatch: PropTypes.func,
}

const ConnectFunction = connect(mapStateToProps)(Search)

const StaticProductForImage = props => (
  <StaticQuery
    query={graphql`
      query SearchIndexExampleQuery {
        siteSearchIndex {
          index
        }
      }
    `}
    render={productForImage => <ConnectFunction data={productForImage} {...props} />}
  />
)

export default StaticProductForImage
